import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { Section, Button, Title, Text, Box } from '../components/Core'
import PageWrapper from '../components/PageWrapper'
import Contact from '../sections/common/Contact'
import imgWorkCover from '../assets/image/cover/MtrIntro.webp'
import imgS3 from '../assets/image/details/mtor/MtrMen.webp'
import imgS4 from '../assets/image/details/mtor/MtrHands.webp'

const handleLiveWorkClicked = event => {
  event.stopPropagation()
  window.open('https://mtor.io/', '_blank')
}

const WorkSingle = () => {
  return (
    <>
      <PageWrapper>
        <Container
          fluid
          className="px-sm-5"
          css={`
            margin-top: 92px;
          `}
        >
          <img src={imgWorkCover} alt="" className="img-fluid w-100" />
        </Container>
        <Section className="mt-lg-5">
          <Container>
            <Row>
              <Col lg="8">
                <Text variant="tag">CODING</Text>
                <Title variant="secSm" className="my-4">
                  Project Overview{' '}
                </Title>
                <Text
                  variant="p"
                  css={`
                    max-width: 750px;
                  `}
                >
                  MTOR.IO is a community focused on connecting people who are eager to learn and teach web development. Mentors will have the opportunity to share their experiences, knowledge, and expertise with mentees striving to land their first job in the tech field.
                  field.
                </Text>
              </Col>
            </Row>
          </Container>
        </Section>
        <div className="mt-lg-3">
          <Container>
            <Row>
              <Col lg="4" className="mb-4 mb-lg-0">
                <Text variant="tag">Client</Text>
                <Title variant="cardBig" className="mt-3">
                  MTOR.IO
                </Title>
              </Col>
              <Col lg="4" className="mb-4 mb-lg-0">
                <Text variant="tag">Time Scope</Text>
                <Title variant="cardBig" className="mt-3">
                  1 Month
                </Title>
              </Col>
              <Col lg="4">
                <Button onClick={handleLiveWorkClicked} arrowRight>
                  Live work
                </Button>
              </Col>
            </Row>
          </Container>
        </div>
        <Section className="mt-lg-5">
          <Container>
            <Row>
              {/*         
              <Col xs="12" className="mb-5">
                <img src={imgS2} alt="" className="img-fluid w-100" />
              </Col> */}
              <Col lg="6" className="mb-5 mb-lg-0 pr-lg-4">
                <img src={imgS3} alt="" className="img-fluid w-100" />
              </Col>

              <Col lg="6" className="pl-lg-4">
                <img src={imgS4} alt="" className="img-fluid w-100" />
              </Col>
            </Row>
          </Container>
        </Section>
        <Section className="pt-4 pb-0">
          <Container>
            <Title variant="secSm" className="mb-5 pb-lg-4">
              Process
            </Title>
            <Row>
              <Col lg="6" className="mb-5 pr-lg-5">
                <Title variant="cardBig" className="mb-4">
                  1. Briefing
                </Title>
                <Text variant="p">
                  Code a custom landing page based on the provided designs, with an implemented Typeform.
                </Text>
              </Col>
              <Col lg="6" className="mb-5 pl-lg-5">
                <Title variant="cardBig" className="mb-4">
                  2. Deliverables
                </Title>
                <Text variant="p">
                  Design implementation for a responsive landing page{' '}
                  <br />
                </Text>
              </Col>
              <Col lg="6" className="mb-5 pr-lg-5">
                <Title variant="cardBig" className="mb-4">
                  3. Strategy
                </Title>
                <Text variant="p">
                  I chose to build the website using Gatsby, a fast and flexible React-based framework, to explore new technology.
                </Text>
              </Col>
              <Col lg="6" className="mb-5 pl-lg-5">
                <Title variant="cardBig" className="mb-4">
                  4. Key Learnings
                </Title>
                <Text variant="p">
                  While Gatsby is excellent for handling a vast amount of dynamic content, it wasn't a perfect use case. Working with a new framework and learning its specifics on top of implementing designs was quite challenging, but I found it satisfying.
                </Text>
              </Col>
            </Row>
          </Container>
        </Section>
        <Box py={4}>
          <Contact />
        </Box>
      </PageWrapper>
    </>
  )
}
export default WorkSingle
